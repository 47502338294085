/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType';
import PropTypes from 'prop-types';
import Button from '../../atoms/button/Button';
import { DottedFadebarVariantA5mm, DottedFadebarVariantB5mm } from '../../atoms/dotted';
import BaseDotted from '../../atoms/dotted/BaseDotted';
import FrontasticImage from '../../atoms/frontasticImage';
import FullPageWidthWrapper from '../../molecules/Layout/FullPageWidthWrapper';

const HeroBanner = ({ title, desc, categoryList, mobile, tablet, desktop, isDotted, dottedColor }) => {
  const deviceType = useDeviceType();
  const WARNING_MESSAGE_VIDEO = 'We only support MP4 video format';

  const getConfigurationForCurrentDevice = () => {
    switch (deviceType) {
      case 'mobile':
        return mobile;
      case 'tablet':
        return tablet;
      default:
        return desktop;
    }
  };
  const configuration = getConfigurationForCurrentDevice();
  const height = configuration.height;
  const video = configuration.video;
  const media = configuration.media;

  const style = {
    height: `${height}px`,
  };

  const renderCategoryList = () =>
    categoryList &&
    categoryList.map((category, index) => {
      const { name, variant, reference } = category;
      const key = name + index;

      if (!name || !reference) {
        return null;
      }

      return (
        <Button key={key} reference={reference} className={`btn btn-${variant}`} darkTheme>
          {name}
        </Button>
      );
    });

  const renderDottedBar = () => {
    if (!isDotted || video) {
      return null;
    }

    return (
      <div className="hero-banner--dotted-bar">
        <BaseDotted dottedSVG={<DottedFadebarVariantA5mm fill={dottedColor} />} height={14} isTop isInsetTop />
        <BaseDotted
          dottedSVG={<DottedFadebarVariantB5mm fill={dottedColor} />}
          height={14}
          isTop
          isInsetTop
          from={'right'}
        />
      </div>
    );
  };

  const checkVideoFormat = (videoUrl) => {
    let result = true;
    const isMp4 = videoUrl.indexOf('.mp4');

    if (isMp4 < 0) {
      result = false;
    }

    return result;
  };

  const renderVideo = () => {
    if (!video) {
      return null;
    }

    checkVideoFormat(video);

    if (!checkVideoFormat(video)) {
      return <p>{WARNING_MESSAGE_VIDEO}</p>;
    }

    return <video className="hero-banner--video" autoPlay muted loop src={video} />;
  };

  const lengthOfCategoryList = categoryList && categoryList.length;

  return (
    <FullPageWidthWrapper>
      <FrontasticImage
        media={media.media}
        height={height}
        alt={title}
        style={{
          objectFit: 'cover',
          objectPosition: 'center',
          ...style,
        }}
        priority
      />
      <div className="absolute left-0 top-0 h-full w-full">
        <div className="o-wrapper">
          <div className="hero-banner" style={style}>
            {renderDottedBar()}
            {renderVideo()}

            <div className="o-grid o-region">
              <div className="o-cell o-cell--12">
                <div className="hero-banner--container" style={style}>
                  <div className="hero-banner--main">
                    <div className="hero-banner--content md:self-end">
                      {title && <h1 className="headline">{title}</h1>}
                      {desc && <div className="sub-h1">{desc}</div>}
                    </div>

                    <div
                      className={`md:self-end hero-banner--category-list${
                        lengthOfCategoryList > 3 ? ' hero-banner--category-list__over' : ''
                      }`}
                    >
                      {renderCategoryList()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FullPageWidthWrapper>
  );
};

HeroBanner.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  mobile: PropTypes.any,
  tablet: PropTypes.any,
  desktop: PropTypes.any,
  isDotted: PropTypes.bool,
  dottedColor: PropTypes.string,
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      reference: PropTypes.object,
      variant: PropTypes.oneOf(['default', 'cta', 'secondary']),
    }),
  ),
};

export default HeroBanner;
