import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import HeroBanner from '../../patterns/organisms/HeroBanner'

const HeroBannerTastic = ({
    data: {
        title,
        desc,
        isDotted,
        dottedColor,
        categoryList,
        heightMobile,
        videoMobile,
        mediaMobile,
        heightTablet,
        videoTablet,
        mediaTablet,
        heightDesktop,
        videoDesktop,
        mediaDesktop,
    } }) => {
    const mobile = {
        height: heightMobile,
        video: videoMobile,
        media: mediaMobile,
    }
    const tablet = {
        height: heightTablet,
        video: videoTablet,
        media: mediaTablet,
    }
    const desktop = {
        height: heightDesktop,
        video: videoDesktop,
        media: mediaDesktop,
    }
    return (
        <HeroBanner
            title={title}
            desc={desc}
            categoryList={categoryList}
            mobile={mobile}
            tablet={tablet}
            desktop={desktop}
            isDotted={isDotted}
            dottedColor={dottedColor}
        />
    )
}

HeroBannerTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify({ translate: true })(HeroBannerTastic)
